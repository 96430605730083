<template>
  <div
    v-loading="loading"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <!-- 模糊搜索框 -->
    <div class="search-box">
      <el-input
        v-model="searchBoxValue"
        :placeholder="$t('placeholder.input')"
        size="mini"
        style="width: 160px"
        suffix-icon="el-icon-search"
        @blur="fuzzy_search"
        @keyup.enter.native="fuzzy_search"
      >
      </el-input>
    </div>
    <!-- 批量操作栏 -->
    <el-row type="flex" justify="end">
      <el-col :span="3" style="text-align: right">
        <button
          v-if="selectingMultiple"
          class="word_button"
          type="submit"
          @click="deleteMultiple"
        >
          <i class="iconfont icon-projectmanger_delbin" style="font-size: 12px">
          </i>
          {{ $t("btn.forceDeleteBtn") }}
        </button>
      </el-col>
      <el-col :span="3" style="text-align: right">
        <button
          v-if="selectingMultiple"
          class="word_button"
          type="button"
          @click="recoverMultiple"
        >
          <i
            class="iconfont icon-projectmanger_recover"
            style="font-size: 12px"
          >
          </i>
          {{ $t("btn.revertBtn") }}
        </button>
      </el-col>
      <el-col :span="3" style="text-align: right">
        <button
          class="word_button"
          type="button"
          @click="selectMultipleClicked"
        >
          <i
            :class="{ 'iconfont icon-edit': !selectingMultiple }"
            style="font-style: normal; font-size: 12px"
          >
            <i
              v-if="selectingMultiple"
              class="iconfont icon-exit"
              style="font-size: 12px"
            ></i>
          </i>
          {{ selectingMultiple ? $t("btn.exitBtn") : $t("btn.batchBtn") }}
        </button>
      </el-col>
    </el-row>
    <!-- 文件列表 -->
    <el-table
      :data="tableData"
      style="width: 100%; background: white"
      class="clickable-rows"
      :header-cell-style="{
        color: '#383838',
      }"
      :cell-style="changeCellStyle"
      @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="selectingMultiple" type="selection" min-width="15">
        <!--如点击了批量操作，勾选框会显示/隐藏-->
      </el-table-column>
      <el-table-column
        prop="fileTypeName"
        :label="$t('bin.table.type')"
        min-width="25%"
        cursor="pointer"
      >
      </el-table-column>
      <el-table-column prop="key" label="Key" min-width="25%">
        <template slot="header">
          <div class="sortable-column-header" @click="filekey_sort_toggle">
            Key
            <div class="caret-wrapper">
              <i
                :class="{
                  'el-icon-caret-top': true,
                  'el-icon-caret-colored': sortby == 'fileKey' && desc == false,
                }"
              ></i>
              <i
                :class="{
                  'el-icon-caret-bottom': true,
                  'el-icon-caret-colored': sortby == 'fileKey' && desc == true,
                }"
              ></i>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        :label="$t('bin.table.name')"
        min-width="40%"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="delBy"
        :label="$t('bin.table.deletePeople')"
        min-width="25%"
      >
      </el-table-column>
      <el-table-column
        prop="deleteTime"
        :label="$t('bin.table.deleteDate')"
        min-width="25%"
      >
        <template slot="header">
          <div class="sortable-column-header" @click="delTime_sort_toggle">
            {{ $t("bin.table.deleteDate") }}
            <div class="caret-wrapper">
              <i
                :class="{
                  'el-icon-caret-top': true,
                  'el-icon-caret-colored': sortby == 'delTime' && desc == false,
                }"
              ></i>
              <i
                :class="{
                  'el-icon-caret-bottom': true,
                  'el-icon-caret-colored': sortby == 'delTime' && desc == true,
                }"
              ></i>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('test.setStatus.option')" min-width="15%">
        <template slot-scope="scope">
          <el-dropdown>
            <span class="el-dropdown-link" style="color: #84aace">
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
              &nbsp;&nbsp;
              <i
                class="iconfont icon-a-216gengduo-shuxiang"
                style="color: rgb(48, 100, 143)"
              />
              &nbsp;&nbsp;
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                class="operate_button"
                @click.native="
                  () => {
                    recoverOnly([scope.row]);
                  }
                "
              >
                <i class="el-icon-refresh-left">
                  &nbsp;&nbsp;&nbsp;&nbsp;{{
                    $t("btn.revertBtn")
                  }}&nbsp;&nbsp;</i
                >
              </el-dropdown-item>
              <el-dropdown-item
                class="operate_button"
                @click.native="
                  () => {
                    deleteOnly([scope.row]);
                  }
                "
              >
                <i class="el-icon-delete">
                  &nbsp;&nbsp;&nbsp;&nbsp;{{
                    $t("btn.forceDeleteBtn")
                  }}&nbsp;&nbsp;</i
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="“my-page”" style="height: 6vh; width: 100%; margin-top: 10px">
      <el-pagination
        :current-page.sync="currentPage"
        background
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="total_elements"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  batchDel_bin_file,
  batchRecover_bin_file,
  delete_bin_file,
  get_user_bin,
  recover_bin_file,
} from "@/network/home/index.js";
import vmson from "@/utils/vmson";
import { mapActions, mapGetters } from "vuex";
import { get_filetype_List } from "@/network/fileType/index.js";

export default {
  name: "HomeBinVue",
  inject: ["reload"],
  data() {
    return {
      type1: true,
      type2: true,
      type3: true,
      type4: true,
      type5: true,

      activeIndex: "/home/bin",
      selectingMultiple: false,
      selected: [],
      rsData: [],
      // 被删除文件表格数据
      tableData: [],
      // 页面数据是否在加载
      loading: true,
      // 模糊搜索框的值
      searchBoxValue: "",
      // 分页器当前页
      currentPage: 1,
      // 文件key排序，true为降序，false为升序
      fileKeySort: true,
      // 删除时间排序
      delTimeSort: true,
      // 是否降序
      desc: true, // 默认降序
      // 按什么排序
      sortby: "delAt", // 默认文件key
      // 分页器的总元素数
      total_elements: 0,
    };
  },
  computed: {
    ...mapGetters(["user_list", "fileType"]),
  },
  mounted() {
    vmson.$on("set_selected", (val) => {
      this.$store.commit("SET_CURRENT_TIME", nowTime);
    });
  },
  created() {
    this.get_table_data();
  },
  methods: {
    ...mapActions(["setFileType"]),
    // 获取表格数据
    get_table_data() {
      this.loading = true;
      get_user_bin(
        this.get_pid(),
        this.currentPage - 1,
        20,
        this.desc,
        this.sortby,
        this.searchBoxValue
      )
        .then((res) => {
          this.total_elements = res.totalElements;
          this.tableData = res.content;
          this.tableData.forEach((item) => {
            item.delBy = this.matchUserNickname(item.delBy, "");
            item.fileTypeName = this.fileType[item.fileTypeId]
              ? this.fileType[item.fileTypeId].name
              : "";
            let deleteTime = new Date(item.delTime);
            item.deleteTime = `${deleteTime.getFullYear()}-${
              deleteTime.getMonth() + 1
            }-${deleteTime.getDate()}
          ${
            deleteTime.getHours() > 9
              ? deleteTime.getHours()
              : "0" + deleteTime.getHours()
          }:${
              deleteTime.getMinutes() > 9
                ? deleteTime.getMinutes()
                : "0" + deleteTime.getMinutes()
            }:${
              deleteTime.getSeconds() > 9
                ? deleteTime.getSeconds()
                : "0" + deleteTime.getSeconds()
            }`;
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 模糊搜索
    fuzzy_search() {
      this.get_table_data();
    },

    // 改变按照Key方式
    filekey_sort_toggle() {
      this.fileKeySort = !this.fileKeySort;
      this.desc = this.fileKeySort;
      this.sortby = "fileKey";
      this.get_table_data();
    },

    // 改变按照删除时间排序方式
    delTime_sort_toggle() {
      this.delTimeSort = !this.delTimeSort;
      this.desc = this.delTimeSort;
      this.sortby = "delTime";
      this.get_table_data();
    },

    // 翻页器翻页
    handleCurrentChange() {
      this.get_table_data();
    },

    // 改变单元格样式
    changeCellStyle(row) {
      if (row.column.label === "Key") {
        return "color: #FFC300;font-weight: 700;"; // 修改的样式
      } else {
        return "color:#636363; font-weight: 700;";
      }
    },

    // 筛选文件类型
    handleCommand(command) {
      //根据key进行判断
      if (command == "需求") {
        //页面信息条件筛选过滤
      }
    },

    ifRename(node) {
      let str =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";
      for (let i = 6; i > 0; --i) {
        result += str[Math.floor(Math.random() * str.length)];
      }

      rename_cover(
        this.$store.getters.email,
        node.file_name,
        node.file_name + "-" + this.$t("canvas.messages.ano") + result,
        node.fid
      ).then((res) => {
        this.reload();
      });
      return 1;
    },
    selectMultipleClicked() {
      this.selectingMultiple = !this.selectingMultiple;
    },
    handleSelectionChange(selection) {
      this.selected = selection;
    },
    deleteMultiple() {
      if (this.selected.length > 0) {
        this.$confirm(
          this.$t("bin.table.forceDeleteForm.desc"),
          this.$t("bin.table.forceDeleteForm.title"),
          {
            confirmButtonText: this.$t("btn.forceDeleteBtn"),
            confirmButtonClass: "delete_button_class",
            cancelButtonText: this.$t("btn.cancelBtn"),
            cancelButtonClass: "delete_cancel_class",
            type: "warning",
          }
        )
          .then(() => {
            //通知后端从数据库中删除
            const fileKeys = this.selected.map((item) => {
              return item.key;
            });
            const params = {
              projectId: this.get_pid(),
              fileKeys: fileKeys,
            };

            batchDel_bin_file(params).then((res) => {
              this.reload();
              this.$message({
                type: "success",
                message: this.$t("bin.message.deleteSuccess"),
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.$t("bin.message.info"),
            });
          });
      }
    },
    recoverMultiple() {
      if (this.selected.length > 0) {
        //通知后端从数据库中标记文件为正常文件
        //通知后端从数据库中删除
        const fileKeys = this.selected.map((item) => {
          return item.key;
        });
        const params = {
          projectId: this.get_pid(),
          fileKeys: fileKeys,
        };
        batchRecover_bin_file(params).then((res) => {
          this.reload();
          this.$message({
            type: "success",
            message: this.$t("bin.message.revertSuccess"),
          });
        });
      }
    },
    // 彻底删除某行
    deleteOnly(row) {
      this.$confirm(
        this.$t("bin.table.forceDeleteForm.desc"),
        this.$t("bin.table.forceDeleteForm.title"),
        {
          confirmButtonText: this.$t("btn.forceDeleteBtn"),
          confirmButtonClass: "delete_button_class",
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      )
        .then(() => {
          this.loading = true;
          delete_bin_file(this.get_pid(), row[0].key).then((res) => {
            this.$message({
              type: "success",
              message: this.$t("bin.message.deleteSuccess"),
            });
            this.get_table_data();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("bin.message.info"),
          });
        });
    },
    // 恢复某行
    recoverOnly(row) {
      this.loading = true;
      recover_bin_file(this.get_pid(), row[0].key).then((res) => {
        this.$message({
          type: "success",
          message: this.$t("bin.message.revertSuccess"),
        });
        this.get_table_data();
      });
    },
  },
};
</script>
<style scoped>
.sortable-column-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.caret-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.el-icon-caret-top {
  transform: translate(0, 3px);
}

.el-icon-caret-bottom {
  transform: translate(0, -3px);
}

.el-icon-caret-colored {
  color: #068bff;
}

.clickable-rows::before {
  height: 0px;
}
.clickable-rows {
  cursor: default;
  height: 79vh;
  overflow: auto;
  background: white;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.word_button {
  height: 30px;
  border: 0;
  background: transparent;
  white-space: nowrap;
  cursor: pointer;
  color: rgba(1, 76, 170, 1);
  font-family: "思源黑体";
  text-align: right;
}

.word_button:hover {
  color: #0074b7;
}
/* .operate_button {
  display: block;
  width: 60px;
  margin: 15px 20px;
  text-align: left;
  cursor: pointer;
  color: #656667;
} */
/* .operate_button:hover {
  color: #46b4f4;
} */
.el-dropdown-menu__item {
  line-height: 0;
  padding: 0;
  font-size: 12px;
  margin: 15px 20px;
  text-align: left;
  cursor: pointer;
  color: #656667;
}
.el-dropdown-menu__item:hover {
  color: #46b4f4;
  background: white;
}
/deep/.el-checkbox__inner {
  width: 20px;
  height: 20px;
}
/deep/.el-checkbox__inner:hover {
  border-color: #30648f;
}
/deep/.el-checkbox__inner::after {
  border: 2px solid white;
  border-left: 0px;
  border-top: 0px;
  height: 13px;
  left: 5px;
  position: absolute;
  top: 0px;
  width: 6px;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #568cb8;
}
/* /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #30648f;
  border-color: #30648f;
} */
/* /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #30648f;
} */
</style>
<style lang="scss" scoped>
.search-box {
  width: 160px;
  height: 20px;
  margin-left: 0.5%;
}
.i {
  cursor: pointer;
}
</style>
